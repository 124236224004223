var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex flex-column justify-between" }, [
    _c(
      "div",
      { staticClass: "link pointer dim mb2", on: { click: _vm.handleBack } },
      [
        _c("i", { staticClass: "material-icons f3 light-gray mr2 v-btm" }, [
          _vm._v("arrow_back"),
        ]),
        _c("span", { staticClass: "f4 light-gray fw1 mr4" }, [
          _vm._v("Go back"),
        ]),
      ]
    ),
    _c("div", { staticClass: "f4 light-gray fw1 mt2" }, [_vm._v("New Ad")]),
    _c("div", { staticClass: "flex flex-column" }, [
      _c(
        "form",
        {
          staticClass: "w-100",
          on: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "mb2" },
            [
              _c("BaseLabel", {
                staticClass: "mb1",
                attrs: { for: "nameOfAd", text: "Name of Ad*" },
              }),
              _c("input", {
                staticClass:
                  "db bg-adori-very-light-gray bw0 br2 ph3 pv3 f6 white br mr3 dib w-50",
                attrs: { placeholder: "Name of the Ad*" },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mb2" },
            [
              _c("BaseLabel", {
                staticClass: "mb1",
                attrs: { for: "type", text: "Type of Ad*" },
              }),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.audioType,
                      expression: "audioType",
                    },
                  ],
                  staticClass:
                    "db mt2 bg-adori-gray gray ba b--gray pa1 br2 f6 mb2 w-50 h2",
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.audioType = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                      function ($event) {
                        return _vm.onAudioTypeChange($event)
                      },
                    ],
                  },
                },
                [
                  _c(
                    "option",
                    { staticClass: "dn", attrs: { disabled: "disabled" } },
                    [_vm._v("Select a Type")]
                  ),
                  _vm._l(_vm.audioTags, function (type) {
                    return _c("option", { key: type }, [_vm._v(_vm._s(type))])
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mb2" },
            [
              _c("BaseLabel", {
                staticClass: "mb1",
                attrs: { for: "campaignName", text: "Campaign Name" },
              }),
              _c("input", {
                staticClass:
                  "db bg-adori-very-light-gray bw0 br2 ph3 pv3 f6 white br mr3 dib w-50",
                attrs: { placeholder: "Campaign Name" },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mb2" },
            [
              _c("BaseLabel", {
                staticClass: "mb1",
                attrs: { for: "brandName", text: "Brand Name" },
              }),
              _c("input", {
                staticClass:
                  "db bg-adori-very-light-gray bw0 br2 ph3 pv3 f6 white br mr3 dib w-50",
                attrs: { placeholder: "Brand Name" },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mb2" },
            [
              _c("BaseLabel", {
                staticClass: "mb1",
                attrs: { for: "status", text: "Status" },
              }),
              _c("BaseCheckbox", {
                staticClass: "mr2",
                attrs: { text: "Active" },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "mb2" }, [
            _vm.audioPlayUrl === null
              ? _c("div", { staticClass: "flex flex-column items-center" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "w-100 h5 bg-adori-light-gray ba b--light-gray b--dashed br2 pa5 flex flex-column items-center justify-center pointer",
                      on: { click: _vm.handleUploadAudio },
                    },
                    [
                      _c(
                        "i",
                        { staticClass: "material-icons f-headline silver mb2" },
                        [_vm._v("publish")]
                      ),
                      _c("div", { staticClass: "f4 silver" }, [
                        _vm._v("Upload"),
                      ]),
                    ]
                  ),
                ])
              : _c(
                  "audio",
                  {
                    attrs: {
                      controls: "",
                      src: _vm.audioPlayUrl,
                      type: "audio/mpeg",
                    },
                  },
                  [
                    _vm._v(
                      "\n          Your browser does not support the\n          "
                    ),
                    _c("code", [_vm._v("audio")]),
                    _vm._v(" element.\n        "),
                  ]
                ),
          ]),
          _c(
            "div",
            { staticClass: "flex w-100" },
            [
              _c("BaseButtonRed", {
                staticClass: "ph4 mt3 mr1 fr",
                attrs: { text: "Add Audio Ad", onClick: _vm.addAudioAd },
              }),
            ],
            1
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }