





































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { AudioTags } from './monetize'
@Component
export default class AddAudioAd extends Vue {
  @Prop(Function) handleCancel!: () => void

  @Getter audioPlayUrl!: string

  @Action clearAudioUploader!: any
  @Action clearSelectedFile!: any

  audioType: string = 'Promo'
  mounted() {}
  get audioTags() {
    return AudioTags
  }
  handleBack() {
    this.$emit('handleCancel')
  }
  addAudioAd() {}
  handleUploadAudio() {
    this.$store.dispatch('uploadNewAd')
  }
  onAudioTypeChange() {
    console.log(this.audioType)
  }

  async beforeDestroy() {
    await this.clearAudioUploader()
    await this.clearSelectedFile()
  }
}
